import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import QuestionTooltipBlack from 'components/tooltips/QuestionTooltipBlack';

import InsightsSectionWrapper from 'components/insight_components/insight_section_wrapper';

import DistributionsImg from 'img/insight_distribution.svg';
import DownloadCsv from 'components/insight_components/download_csv';
import Chart from 'components/insight_components/score_distribution_chart';
import { exportInsightsScoreDistribution } from 'requests/AccountInsightsRequests';
import Tooltip from 'libraries/Tooltip';
import { accountInsightsStoreCtx } from '../../store';
import { DISABLED_TOOLTIP_TEXT } from '../../constants';

const styles = theme => ({
    averageScore: {
        color: theme.palette.primary.main
    },
    select: {
        textTransform: 'capitalize',
        width: 235
    },
    exportButton: {
        minWidth: 145,
        fontWeight: 700
    },
    menuItem: {
        textTransform: 'capitalize',
        maxWidth: 250,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    whiteBackground: {
        backgroundColor: 'white'
    },
    tooltipWrapper: {
        width: 150,
        marginLeft: 'auto'
    }
});

const InsightsScoreDistributions = observer(({ classes, isPublic }) => {
    const { scoreDistributionData, baseParams, isTeamUser, canCompanyExportCSV } = useContext(accountInsightsStoreCtx);

    const header = (
        <Typography variant="h5" className="u-txt--left">
            Score Distribution
            <QuestionTooltipBlack className="u-pdn--tx2 u-pdn--bx2" label={<>Shows distribution of<br />all candidate scores</>} />
        </Typography>
    );

    let displayedRanges = [];
    let displayedAverageScore = null;

    if (scoreDistributionData) {
        const { scoreDistribution, averageScore } = scoreDistributionData;
        displayedRanges = scoreDistribution;
        displayedAverageScore = averageScore;
    }

    return (
        <div className={classes.whiteBackground}>
            <InsightsSectionWrapper
                header={header}
                imgSrc={DistributionsImg}
                isEmpty={!scoreDistributionData}
                emptyLabel="score distribution"
            >
                {
                    displayedAverageScore !== null && (
                        <Typography variant="h5" className="u-txt--left">Average Score&nbsp;
                            <span className={classes.averageScore}>
                                {displayedAverageScore}%
                            </span>
                        </Typography>
                    )
                }
                <Chart
                    value={displayedRanges}
                    tooltipLabel="Candidate"
                />
                {
                    !isPublic && canCompanyExportCSV && (isTeamUser ? (
                        <Tooltip label={DISABLED_TOOLTIP_TEXT}>
                            <div className={classes.tooltipWrapper}>
                                <DownloadCsv
                                    request={null}
                                    enabled={Boolean(scoreDistributionData)}
                                    filename={`Account-insights-distribution-${new Date().toISOString().slice(0, 10)}.csv`}
                                    disabled
                                />
                            </div>
                        </Tooltip>
                    ) : (
                        <DownloadCsv
                            request={() => exportInsightsScoreDistribution(baseParams)}
                            enabled={Boolean(scoreDistributionData)}
                            filename={`Account-insights-distribution-${new Date().toISOString().slice(0, 10)}.csv`}
                        />
                    ))
                }
            </InsightsSectionWrapper>
        </div>
    );
});

export default withStyles(styles)(withRouter(InsightsScoreDistributions));
