import React, { useContext, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import Pagination from 'components/board/board_pagination';
import TableContainer from '@mui/material/TableContainer';
import DownloadCSVRecursively from 'components/insight_components/download_csv_recursively';
import { exportInsightAuditions } from 'requests/AccountInsightsRequests';
import Tooltip from 'libraries/Tooltip';
import { accountInsightsStoreCtx } from '../../store';
import useStyles from './styles';
import SuccessfulTable from './components/SuccessfulTable';
import UnsuccessfulTable from './components/UnsuccessfulTable';
import { DISABLED_TOOLTIP_TEXT } from '../../constants';

const AssessmentsTable = observer(({ isPublic }) => {
    const [activeTab, setActiveTab] = useState(0);
    const { insightAuditions, handleChangeAssessmentsPage, baseParams, isTeamUser, canCompanyExportCSV } = useContext(accountInsightsStoreCtx);

    const { wrapper, title, tabWrapper, activeTab: activeTabClass, tab, noData, statusLabel, tableContainer, downloadAuditionsButton, openStatusClass } = useStyles();

    const handleTabChange = (newTab) => {
        setActiveTab(newTab);
    };

    const hasData = insightAuditions && insightAuditions.items && insightAuditions.items.length > 0;

    const getAuditionStatus = (audition) => {
        const { archived, closed } = audition;

        let status = '';
        if (archived) {
            status = 'archived';
        } else if (closed) {
            status = 'closed';
        } else {
            status = 'open';
        }

        return (
            <span className={clsx(statusLabel, status === 'open' && openStatusClass)}>({status})</span>
        );
    };

    const csvSlug = useMemo(() => (activeTab === 0 ? 'successful' : 'unsuccessful'), [activeTab]);

    return (
        <div className={wrapper}>
            <div className={title}>Assessments</div>
            <div className={tabWrapper}>
                <div
                    className={clsx(tab, { [activeTabClass]: activeTab === 0 })}
                    onClick={() => handleTabChange(0)}
                >
                    SUCCESSFUL CANDIDATES
                </div>
                <div
                    className={clsx(tab, { [activeTabClass]: activeTab === 1 })}
                    onClick={() => handleTabChange(1)}
                >
                    UNSUCCESSFUL CANDIDATES
                </div>
            </div>
            <TableContainer className={tableContainer}>
                {
                    activeTab === 0
                        ? <SuccessfulTable data={insightAuditions} hasData={hasData} getAuditionStatus={getAuditionStatus} />
                        : <UnsuccessfulTable data={insightAuditions} hasData={hasData} getAuditionStatus={getAuditionStatus} />
                }
                {
                    hasData && (
                        <div className="u-mrg--tx2">
                            <Pagination
                                currentPage={insightAuditions?.currentPage}
                                totalPages={Math.ceil(insightAuditions?.total / 4)}
                                handleChangePage={handleChangeAssessmentsPage}
                            />
                            {!isPublic && canCompanyExportCSV && (isTeamUser ? (
                                <div>
                                    <Tooltip label={DISABLED_TOOLTIP_TEXT}>
                                        <div className={clsx(insightAuditions?.total > 4 && downloadAuditionsButton)}>
                                            <DownloadCSVRecursively
                                                request={null}
                                                params={null}
                                                filename={`Account-insights-${csvSlug}-candidates`}
                                                exportMessage="Exporting assessments..."
                                                disabled
                                            />
                                        </div>
                                    </Tooltip>
                                </div>
                            ) : (
                                <div className={clsx(insightAuditions?.total > 4 && downloadAuditionsButton)}>
                                    <DownloadCSVRecursively
                                        request={exportInsightAuditions}
                                        params={{
                                            ...baseParams,
                                            successful: activeTab === 0
                                        }}
                                        filename={`Account-insights-${csvSlug}-candidates`}
                                        exportMessage="Exporting assessments..."
                                    />
                                </div>
                            ))}
                        </div>
                    )
                }
            </TableContainer>
            {
                !hasData && (
                    <div className={noData}>
                        No Assessments
                    </div>
                )
            }
        </div>
    );
});

export default AssessmentsTable;
