import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Pie, PieChart } from 'recharts';
import ButtonBase from '@mui/material/ButtonBase';
import { formatTimestamp } from 'helper/commonFunctions';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import {
    exportCsatFeedbackCsv,
    getCsatFeedbacks,
    getFiltersForCsatFeedbacks
} from 'requests/AccountInsightsRequests';
import CandidatePhoto from 'components/photos/candidate_photo';
import FeedbackModal from 'components/insight_components/feedback_modal';
import clsx from 'clsx';
import NoDataIcon from 'img/no_data.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from '../../styles';
import { DISABLED_TOOLTIP_TEXT } from '../../../../constants';

const getScoreColor = (isLatest, score) => {
    if (isLatest) return '#1247F6';
    if (score >= 4) return '#00B898';
    if (score >= 3) return '#FC9935';
    return '#FF113D';
};

const CandidateCsat = observer(({ data, generalFilterParams, isPublic, isTeamUser, canCompanyExportCSV }) => {
    const [dialogOpened, setDialogOpened] = useState(false);
    const isTablet = useMediaQuery('(max-width: 1230px)');

    const { metricsWrapper, sectionTitle, csatChartsWrapper, divider, linkClassName, csatFeedbacks, noData, feedbackWrapper } = useStyles();
    const { score, feedbacks } = data;

    const handleDialog = () => {
        setDialogOpened(!dialogOpened);
    };

    const hasData = feedbacks && feedbacks.length > 0;

    const renderFeedbackItem = (feedback, idx, isLatest) => {
        const { candidate, score: feedbackScore, comment, createdAt, auditionName } = feedback;
        return (
            <div key={idx} className={clsx(feedbackWrapper, isLatest && 'latest')}>
                <div className="u-dsp--f u-jc--sb u-ai--center header">
                    <div className="name u-dsp--f u-ai--center">
                        <CandidatePhoto
                            user={{ ...candidate, candidateImageUrl: candidate.candidateProfileImage }}
                        />
                        <TruncateWithTooltip text={candidate.fullName} width={isLatest ? 200 : 400} />
                    </div>
                    <div className="score">CSAT SCORE <span style={{ color: getScoreColor(isLatest, feedbackScore) }}>{feedbackScore}</span></div>
                </div>
                <div className="comment">
                    {isLatest ? (
                        <TruncateWithTooltip text={comment} width={500} />
                    ) : (
                        <div>{comment}</div>
                    )}
                </div>
                <div className="assessment u-dsp--f">
                    Assessment:&nbsp; <TruncateWithTooltip text={auditionName} width={300} />
                </div>
                <div className="date">{formatTimestamp(createdAt)}</div>
            </div>
        );
    };

    const chartWidth = isTablet ? 180 : 230;

    return (
        <>
            <div className={metricsWrapper}>
                <div className={csatChartsWrapper}>
                    <div className={sectionTitle}>AVERAGE CSAT SCORE</div>
                    <PieChart width={chartWidth} height={chartWidth}>
                        <Pie
                            dataKey="value"
                            data={hasData ? [
                                { value: score, fill: '#009879' },
                                { value: 5 - score, fill: '#F4F4F7' }
                            ] : [{ value: 100, fill: '#F4F4F7' }]}
                            strokeWidth={0}
                            isAnimationActive={false}
                        />
                    </PieChart>
                    <div className="score">{hasData ? (<span>{score} / 5</span>) : (<span className={noData}>No Data Available</span>)}</div>
                </div>
                <div className={divider} />
                <div className="u-wdt--100p">
                    <div className="u-dsp--f u-jc--sb u-mrg--bx2">
                        <div className={sectionTitle}>CANDIDATE FEEDBACK</div>
                        {
                            hasData && !isPublic && (
                                <ButtonBase
                                    className={linkClassName}
                                    onClick={handleDialog}
                                >
                                    View all
                                </ButtonBase>
                            )
                        }
                    </div>
                    {
                        hasData ? (
                            <div className={csatFeedbacks}>
                                { feedbacks.map((feedback, idx) => renderFeedbackItem(feedback, idx, true)) }
                            </div>
                        ) : (
                            <div className="u-dsp--f u-ai--center u-jc--center u-wdt--100p u-fdir--column" style={{ height: 250 }}>
                                <img src={NoDataIcon} alt="" />
                                <span className={clsx(noData, 'u-mrg--tx3')}>No feedback available</span>
                            </div>
                        )
                    }
                </div>
            </div>
            <FeedbackModal
                open={dialogOpened}
                onClose={handleDialog}
                canDownloadCSV={canCompanyExportCSV}
                disabledDownloadCsv={isTeamUser ? DISABLED_TOOLTIP_TEXT : null}
                loadParams={{ ...generalFilterParams }}
                loadDataRequest={getCsatFeedbacks}
                loadFilterRequest={getFiltersForCsatFeedbacks}
                requestExportCsv={exportCsatFeedbackCsv}
                title="Candidate Feedback"
                csvSlug="Account-insights-candidate-feedback"
                renderItem={(feedback, idx) => renderFeedbackItem(feedback, idx, false)}
            />
        </>
    );
});

export default CandidateCsat;
