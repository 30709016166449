import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import LinearProgress from '@mui/material/LinearProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from '../../styles';

const METRIC_COLORS = ['#00A98A', '#1247F6', '#4438CB', '#FF4358', '#F87E30', '#FFBB3C'];

const SkillsBreakdown = observer(({ data }) => {
    const { metricsWrapper, topSkillTitle, skillRow, divider, circle, meridianWrapper, noData, noSkillData } = useStyles();
    const isTablet = useMediaQuery('(max-width: 1230px)');

    const chartData = useMemo(() => data?.map((el, i) => ({
        name: el.title,
        value: el.count,
        fill: METRIC_COLORS[i],
        percentage: el.percentage
    })), [data]);

    const hasData = chartData && chartData.length > 0;
    const chartWidth = isTablet ? 290 : 180;

    return (
        <div className={metricsWrapper}>
            <ResponsiveContainer width="35%" height="100%">
                <PieChart width={chartWidth} height={chartWidth}>
                    <Pie
                        dataKey="value"
                        data={hasData ? chartData : [{ value: 100, fill: '#F4F4F7' }]}
                        strokeWidth={hasData ? 2 : 0}
                        outerRadius={isTablet ? 100 : 150}
                        isAnimationActive={false}
                    />
                </PieChart>
            </ResponsiveContainer>
            <div>
                <p className={topSkillTitle}>TOP SKILLS TESTED</p>
                {
                    hasData ? chartData?.map((skill, i) => (
                        <div key={skill.name} className={skillRow}>
                            <span className={circle} style={{ backgroundColor: METRIC_COLORS[i] }} />
                            <TruncateWithTooltip text={skill.name} width={200} rows={2} />
                        </div>
                    )) : (
                        <div className={noData}>No Data Available</div>
                    )
                }
            </div>
            <div className={divider} />
            <div className={meridianWrapper}>
                <p className={clsx(topSkillTitle, 'u-txt--center')}>MEDIAN SCORES IN TOP SKILLS</p>
                {
                    hasData ? chartData?.slice(0, 5).map((skill) => {
                        const { skillProgress, colorPrimary, bar, percentageText, skillNameMedian } = useStyles({ value: skill.percentage });
                        return (
                            <div className="u-dsp--f u-mrg--bx5 u-ai--center u-jc--sb" key={skill.name}>
                                <TruncateWithTooltip text={skill.name} width={200} className={skillNameMedian} />
                                {
                                    skill.percentage === null ? (
                                        <div className={noSkillData}>No Data Available</div>
                                    ) : (
                                        <>
                                            <LinearProgress
                                                key={skill.name}
                                                classes={{
                                                    root: skillProgress,
                                                    colorPrimary,
                                                    bar
                                                }}
                                                variant="determinate"
                                                value={skill.percentage}
                                            />
                                            <span className={percentageText}>{skill.percentage}%</span>
                                        </>
                                    )
                                }
                            </div>
                        );
                    }) : (
                        <div className={clsx(noData, 'u-txt--center')}>No Data Available</div>
                    )
                }
            </div>
        </div>
    );
});

export default SkillsBreakdown;
