import React, { useContext, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';

import { exportCompletionsCsv } from 'requests/AccountInsightsRequests';
import QuestionTooltipBlack from 'components/tooltips/QuestionTooltipBlack';

import InsightsSectionWrapper from 'components/insight_components/insight_section_wrapper';
import CompletionsChart from 'components/insight_components/completions_chart';
import DownloadCsv from 'components/insight_components/download_csv';

import CompletionImg from 'img/insight_completion.svg';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { ButtonBase } from '@mui/material';
import Tooltip from 'libraries/Tooltip';
import { accountInsightsStoreCtx } from '../../store';
import { DISABLED_TOOLTIP_TEXT } from '../../constants';

const styles = theme => ({
    range: {
        fontSize: 12,
        color: theme.palette.grey[500],
        cursor: 'pointer',
        fontWeight: 700,
        '&:first-child': {
            marginRight: 23
        }
    },
    active: {
        color: theme.palette.primary.main,
        cursor: 'default'
    },
    whiteBackground: {
        backgroundColor: 'white'
    },
    tooltipWrapper: {
        width: 150,
        marginLeft: 'auto'
    }
});

const RANGES = {
    PER_DAY: 0,
    PER_WEEK: 1
};

const CompletionsData = observer(({ classes, isPublic }) => {
    const [chartRange, setChartRange] = useState(RANGES.PER_DAY);

    const { completionsChartData, baseParams, isTeamUser, canCompanyExportCSV } = useContext(accountInsightsStoreCtx);

    const preparedChartData = useMemo(() => {
        if (chartRange === RANGES.PER_DAY) {
            return completionsChartData;
        }
        const result = [];
        for (let i = 0; i < completionsChartData.length; i += 7) {
            const group = completionsChartData.slice(i, i + 7);
            const completed_count = group.reduce((sum, item) => sum + item.completed_count, 0);

            let completed_date;
            if (group.length >= 4) {
                // eslint-disable-next-line prefer-destructuring
                completed_date = group[3].completed_date;
            } else {
                // eslint-disable-next-line prefer-destructuring
                completed_date = group[group.length - 1].completed_date;
            }

            result.push({
                completed_date,
                completed_count
            });
        }
        console.log(result);
        return result;
    }, [chartRange, completionsChartData]);

    const header = (
        <Typography variant="h5" className="u-txt--left">
            Candidate Completions
            <QuestionTooltipBlack
                className="u-pdn--tx2 u-pdn--bx2"
                label={(<>
                        Completion chart show numbers<br />of completed assessments by<br />candidates over time
                    </>
                )}
            />
        </Typography>
    );

    return (
        <div className={classes.whiteBackground}>
            <InsightsSectionWrapper
                header={header}
                imgSrc={CompletionImg}
                isEmpty={!completionsChartData || !completionsChartData.length}
                emptyLabel="completion"
                rightPart={(
                    <div className="u-dsp--f">
                        <ButtonBase
                            className={clsx(classes.range, chartRange === RANGES.PER_DAY && classes.active)}
                            onClick={() => setChartRange(RANGES.PER_DAY)}
                        >COMPLETIONS PER DAY
                        </ButtonBase>
                        {
                            !isPublic && (
                                <ButtonBase
                                    className={clsx(classes.range, chartRange === RANGES.PER_WEEK && classes.active)}
                                    onClick={() => setChartRange(RANGES.PER_WEEK)}
                                >
                                    COMPLETIONS PER WEEK
                                </ButtonBase>
                            )
                        }
                    </div>
                )}
            >
                <CompletionsChart
                    value={preparedChartData}
                    tooltipLabel="Completion"
                />
                {
                    !isPublic && canCompanyExportCSV && (isTeamUser ? (
                        <Tooltip label={DISABLED_TOOLTIP_TEXT}>
                            <div className={classes.tooltipWrapper}>
                                <DownloadCsv
                                    request={null}
                                    enabled={Boolean(completionsChartData)}
                                    filename={`Account-insights-completions-${new Date().toISOString().slice(0, 10)}.csv`}
                                    disabled
                                />
                            </div>
                        </Tooltip>
                    ) : (
                        <DownloadCsv
                            request={() => exportCompletionsCsv(baseParams)}
                            enabled={Boolean(completionsChartData)}
                            filename={`Account-insights-completions-${new Date().toISOString().slice(0, 10)}.csv`}
                        />
                    ))
                }
            </InsightsSectionWrapper>
        </div>
    );
});

export default withStyles(styles)(CompletionsData);
